footer {
    background-color: #333;
    padding: 20px 0 0.2rem;
    background-image: linear-gradient(-60deg, rgb(146,95,230) 50%, rgb(219, 122, 203) 50%);
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px black;
}
footer span {
    color:#d82c2e;
    font-size: 25px;
    line-height: 35px;
}
footer span:hover {
    font-size: 27px;
}
footer h5 {
    color:#fff;
    font-weight: normal;
}