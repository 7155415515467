/* Style pour la carte elle-même */
.card-container {
    width: 300px; /* Largeur fixe pour uniformiser les cartes */
    margin: 15px;
    display: flex;
    justify-content: center;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: 5px 3px   #6a0dad ;
    &:hover {
        box-shadow: 0px 0px 16px 16px rgba(0, 0, 0, 0.3); /* Ombre plus marquée au survol */
      }
  }
  h1 {
    text-align: center;
    text-shadow: 2px 2px #5a0ca0;
  }
  
  .card {
    height: 400px; /* Hauteur fixe pour uniformiser les cartes */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    box-shadow: 3px 3px #6a0dad ;
    transition: box-shadow 0.3s ease;
    &:hover {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.3); /* Ombre plus marquée au survol */
  }
  }
  .card .card-body {
  flex-grow: 1; /* Permet à la section principale de la carte d'occuper tout l'espace disponible */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }
  .card-text {
    flex-grow: 1;
  }
  
  .card img {
    height: 180px; /* Taille fixe pour l'image de chaque carte */
    object-fit: cover; /* Ajuste l'image pour remplir l'espace */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &:hover{
        scale: 1.1;
    }
  }
  
  .navbar .button-card {
    background-color: #6a0dad; /* Bouton violet */
    border: none;
    color: white;
    width: 100%; /* Bouton prend toute la largeur */
    padding: 10px;
    margin-top: auto; /* Assure que le bouton est en bas de la carte */
    border-radius: 0 0 8px 8px;
    transition: background-color 0.3s ease;
  }
  
  .button-card:hover {
    background-color: #5a0ca0; /* Couleur légèrement plus foncée au survol */
  }
  
  /* Style pour le conteneur de toutes les cartes */
  .card-container-first {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
  }
  