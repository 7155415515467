/*  */
  
  .title-project {
    font-size: 2.5rem;
    color: #333;
    animation: wave 3s infinite ease-in-out;
    position: relative;
    padding-top: 2rem;
  }
  
  @keyframes wave {
    0% { transform: translateY(0); }
    25% { transform: translateY(-10px); }
    50% { transform: translateY(0); }
    75% { transform: translateY(10px); }
    100% { transform: translateY(0); }
  }