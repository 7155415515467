.custom-popover {
  --bs-popover-max-width: 300px;
  --bs-popover-border-color: #6c757d;
  --bs-popover-header-bg: #f8f9fa;
  --bs-popover-header-color: #212529;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
}

.custom-popover .popover-header {
  font-weight: bold;
  color: #333;
  background-color: blanchedalmond;
  border-bottom: 2px solid #2b0909;
}

.custom-popover .popover-body {
  background-color:white;
  padding: 20px;
}

.custom-popover-button {
  background-color: #4CAF50 !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  transition: all 0.3s ease !important;
}

.custom-popover-button:hover {
  background-color: #45a049 !important;
  transform: translateY(-2px);
}
.popover-link {
  margin-top: 10px;
  text-align: center;
}

.popover-link a {
  color: black;
  text-decoration: underline;
}

.popover-link a:hover {
  color: #0056b3;
}
.button-app {
  border-radius: 5px;
  background-color: purple;
  background-color:  rgb(146, 95, 230);
  box-shadow: 3px 3px black;
  font-weight: bold;

  &:hover{
      scale: 1.2;
      box-shadow: 0px 0px 3px 3px white;
  }
}