@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Gravitas+One&display=swap');
* {
    .chewy-regular {
        font-family: "Chewy", system-ui;
        font-weight: 400;
        font-style: normal;
      }
    .purple {
        color: blueviolet;  
        padding-left: 10px;
    }
    h1 {
        font-family: "Chewy", system-ui;
    }

      
}

.container-app {
        min-height: 100vh;
        position: relative;
        width: 100%;
        overflow: hidden;
        /* background-color: antiquewhite; */
        background-image: url("../../public/images/background-2.webp");
        background-size: cover;
        .button-app {
            border-radius: 5px;
            background-color: purple;
            background-color:  rgb(146, 95, 230);
            box-shadow: 3px 3px black;
            font-weight: bold;
    
            &:hover{
                scale: 1.2;
                box-shadow: 0px 0px 3px 3px white;
            }
        }

        .App {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            overflow: hidden;
            
            .container-header {
                box-sizing: border-box;
                display: block;
                text-align:center;
                margin-right: 1rem;
                text-transform: capitalize;
                color: black;
                position: relative;
                overflow: hidden;
            
                .wrapper {
                    box-sizing: border-box;
                    background-image: linear-gradient(-60deg, rgb(219, 122, 203) 50%, rgb(146, 95, 230) 50%);
                    animation: slide 5s ease-in-out infinite alternate-reverse;
                    margin-left: 10px;
                    border-radius: 10px;
                    box-shadow: 5px 5px black;
                    position: relative ;
                    
                    @keyframes slide {
                        0% {
                        transform:translateX(-25%);
                        }
                        100% {
                        transform:translateX(25%);
                        }
                    }
                    
                }

                #logo {
                    display:flex;
                    float: left;
                    height: 50px;
                    border-radius: 5px 5px 5px 5px;
                    margin-top: 5px;
                    position: absolute;
                    top: 10px;
                    right: auto;
                    left: 20px;
                    text-transform: uppercase;
                    color: #333;
                    font-weight:bold;
                    text-shadow: 2px 2px white;
                    background-color: rgb(34, 163, 214);
                    font-size: 50px;
                    padding-bottom: 5px;
                    z-index: 1;
                    box-shadow: 3px 3px black;

                    &:hover {
                        scale: 1.2;
                        box-shadow: 5px 5px white;
                    }
                
                }
            }
            h1 {
                font-weight: bold;
                text-shadow: 2px 2px white;
                position: relative;
                z-index: 2;
                
                font-family: "Chewy", system-ui, "san-serif";
            }
            
            nav ul li {
                color: white;
                text-shadow: 3px 2px black;
                position: relative;
                z-index: 2;
                list-style: none;
            }
            
            #logo {
                position: relative;
                z-index: 2;
            }
        }
/* Styles de la navbar en position normale */
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative; /* Relative pour la position initiale */
    border-radius: 10px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 2rem;
    background-color: rgb(219, 122, 203);
    box-shadow: 3px 3px black;
    transition: all 0.3s ease-in-out;
}

.navbar-collapse {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

/* Styles des boutons dans la navbar */
.button-app {
    color: white;
    text-decoration: none;
   
    font-weight: bold;
    padding: 10px 15px;
    margin: 5px;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;
}

.button-app:hover {
    background-color: #6a0dad;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

.button-app.active {
    color: white; /* Couleur active */
    font-weight: bold;
    scale: 1.3;
    border-bottom: 2px solid #6a0dad; /* Surligne le bouton actif */
}

/* Hamburger Icon Button */
.navbar-toggler {
    background-color: #6a0dad;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    display: none; /* Hide by default */
}

/* Navbar Sticky (when scrolling) */
.navbar.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    margin: 0;
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Responsive Styles */

/* Pour les petits écrans (tablettes et téléphones) */
@media (max-width: 768px) {
    .navbar-toggler {
        display: block; /* Show hamburger icon */
    }

    .navbar-collapse {
        display: none; /* Hide links by default for small screens */
        flex-direction: column;
        width: 100%;
        text-align: center;
        /* background-color: rgb(219, 122, 203); */
        padding: 1rem 0;
        transition: all 0.3s ease-in-out;
    }

    .navbar-collapse.show {
        display: flex; /* Show links when toggler is clicked */
    }

    .button-app {
        width: 90%;
        padding: 15px;
        margin: 10px auto;
        border-bottom: 1px solid #ddd;
    }

    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }
}


        /* Styles de la navbar en position normale sous le header */


  /* Styles pour les liens de la navbar */
 
  

  
  /* Classe sticky pour coller la navbar en haut */

  

}
.container-skills {
    display: flex;
    margin-top: 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h1 {
        margin: 10px;
    }
    h4 {
        margin-right: 10%;
    }

.row-bar {
    display: flex;
    flex-wrap: wrap; /* Pour permettre une flexibilité lorsque l'écran devient petit */
    justify-content: center;
    
}
.progress-container {
    overflow: hidden;
}
.progress{
    box-sizing: border-box;
     display: flex;
     border: 3px solid black;
     width: 50rem;
     max-width: 100%;
     height: 2rem;
     color:blanchedalmond ;
     padding: 2px;
     margin: 5px;
     border-radius: 5px;
     background-color:blanchedalmond;
     align-items: center;
     transition: all 0.3s ease-in-out;

     &:hover {
        transform: scale(0.95);
     }
    }
.progress-bar {
    box-sizing: border-box;
    background-color: rgb(146, 95, 230);
    text-align: center; /* Centrer le texte */
    color: black;
    display: flex;
    align-items: center; /* Centrer le contenu verticalement */
    justify-content: start; /* Aligner le texte au début */
}
    
     @media (max-width: 1024px) {
        .container-skills {
            margin-top: 3rem;
        }
    
        .progress {
            width: 30rem; /* Réduire la largeur des barres de progression */
        }
        .progress-bar {
            font-size: 0.9rem;
        }
    }
    @media (max-width: 768px) {
        .progress {
            width: 20rem;
            height: 1.5rem;
            padding: 1px;
        }
        .progress-bar {
            width: 0.9rem;
        }
        .row-bar {
            flex-direction: column; /* Empiler les barres verticalement pour les petits écrans */
            align-items: center;
        }
    }
    @media (max-width: 480px) {
        .progress {
            width: 90%; /* Réduire davantage la largeur des barres de progression */
            font-size: 0.8rem; /* Ajuster la taille de la police si nécessaire */
        }
        .progress-bar {
          font-size: 0.8rem
        }
    
        .container-skills h1 {
            font-size: 1.5rem; /* Réduire la taille du titre principal pour les petits écrans */
        }
    
        .container-skills h4 {
            font-size: 1rem; /* Ajuster la taille des sous-titres */
            margin-right: 2%; /* Diminuer la marge */
        }
    }

  /*  background-color: purple;
    width: 100%;
    height: 30px;
    align-items: center;
    margin: 5px;
    padding: 2px */
        
}

.progress-bar {
    background-color: rgb(146, 95, 230);
    text-align: start;
    color: black;
    
}
    .project {
    display: flex;
    flex-direction: column;
    align-items: center;
    
         .divider{
            background-color: red;
            margin: 0 auto 2px;
          }

          li {
            border: 3px solid red;
          }

          .timeline-panel-container {
            border: 2px solid black;
            width: 46%;
            
            
          }
          .timeline-panel-container-inverted{
            border: 2px solid black;
            width: 46%;
            float: right;
          }
          
       } 
.donut {
    display: flex;
}

#portfolio {
    margin-top: 5rem;
}
.portfolio-link {
    display: flex;

    justify-content:center;
}


.title-project {
    font-size: 2.5rem;
    color: #333;
    animation: wave 3s infinite ease-in-out;
    position: relative;
    
  }
  
  @keyframes wave {
    0% { transform: translateY(0); }
    25% { transform: translateY(-10px); }
    50% { transform: translateY(0); }
    75% { transform: translateY(10px); }
    100% { transform: translateY(0); }
  }

