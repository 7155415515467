.project {
  position: relative;
  max-width: 50rem;
  margin: 0 auto;
  padding: 5rem;
  margin-top: 1rem;
  border: 5px solid purple;
  border-radius: 10px;
  box-shadow: 5px 5px rgb(219, 122, 203);
}

.title-project {
  text-align: center;
  margin: 2rem;
  padding-top: 2rem;
}

/* Ligne centrale */
.project::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #333;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.badge-container {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 50px;
  width: 100%;
}

/* Container gauche */
.timeline-panel-container {
  position: relative;
  width: calc(50% - 50px);
  left: 0;
  float: left;
  clear: left;
  border-radius: 10px;
}

/* Container droit */
.timeline-panel-container-inverted {
  position: relative;
  width: calc(50% - 50px);
  right: 0;
  float: right;
  clear: right;
}

/* Contenu des panels */
.timeline-heading {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgb(219, 122, 203);
  position: relative;
  background-color: blanchedalmond;

  &:hover {
      transform: scale(1.1);
  }
}

/* Badge central */
.timeline-badge {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: purple;
  border: 4px solid #fff;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-badge span {
  color: white;
}

/* Flèches */
.timeline-panel-container .timeline-heading::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent purple;
  right: -10px;
  top: 22px;
}

.timeline-panel-container-inverted .timeline-heading::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent purple transparent transparent;
  left: -10px;
  top: 22px;
}

/* Titres et sous-titres */
.timeline-heading h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.timeline-heading h4 {
  margin: 0 0 15px 0;
  color: #666;
}

/* Style responsive */

/* Pour les écrans entre 1024px et 768px (tablettes) */
@media screen and (max-width: 1024px) {
  .project {
      padding: 3rem;
  }

  .project::after {
      width: 4px; /* Réduire la largeur de la ligne centrale */
  }

  .timeline-panel-container,
  .timeline-panel-container-inverted {
      width: calc(50% - 30px);
      padding: 1rem;
  }
}

/* Pour les écrans inférieurs à 768px (téléphones et petites tablettes) */
@media screen and (max-width: 768px) {
  .project::after {
      left: 40px;
  }

  .timeline-badge {
      left: 40px;
      transform: translateX(-50%);
  }

  .timeline-panel-container,
  .timeline-panel-container-inverted {
      width: calc(100% - 90px); /* Réduire la largeur pour les petits écrans */
      float: right;
      clear: both;
      margin-bottom: 2rem;
  }

  .timeline-panel-container .timeline-heading::after,
  .timeline-panel-container-inverted .timeline-heading::after {
      border-width: 10px 10px 10px 0;
      border-color: transparent purple transparent transparent;
      right: auto;
      left: -10px;
  }

  .timeline-heading {
      padding: 15px; /* Réduire l'espace de padding */
  }
}

/* Pour les très petits écrans (inférieurs à 480px) */
@media screen and (max-width: 480px) {
  .project {
      padding: 2rem 1rem; /* Réduire la largeur du padding */
  }

  .timeline-badge {
      width: 30px; /* Réduire la taille du badge */
      height: 30px;
      top: 10px; /* Ajuster la position verticale */
  }

  .timeline-panel-container,
  .timeline-panel-container-inverted {
      width: calc(100% - 60px); /* Réduire encore plus la largeur */
      margin-bottom: 1rem;
  }

  .timeline-heading {
      padding: 10px; /* Réduire davantage le padding */
      box-shadow: 0 1px 10px rgb(219, 122, 203); /* Réduire l'ombre */
  }

  .title-project {
      font-size: 1.8rem; /* Réduire la taille du titre */
  }

  .timeline-heading h3 {
      font-size: 1.2rem; /* Adapter la taille des titres */
  }

  .timeline-heading h4 {
      font-size: 1rem; /* Adapter la taille des sous-titres */
  }

  .project::after {
      left: 20px; /* Déplacer la ligne centrale vers la gauche */
      width: 3px; /* Réduire la largeur de la ligne */
  }
}
