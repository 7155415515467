.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
    margin-top: 2rem;
    background-color: blanchedalmond;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .contact-form label {
    font-weight: bold;
    display: block;
    margin: 0.5rem 0 0.2rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    width: 100%;
    padding: 0.7rem;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .contact-form button:hover {
    scale: 1.1;
    box-shadow: 0px 0px 3px 3px white;
  }
  
  .form-status {
    text-align: center;
    margin-top: 1rem;
    color: green;
    font-weight: bold;
  }
  