
.cv {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10rem;
  
   
    img {
        width: 800px;
        height: 1000px;
        box-shadow: 5px 5px 5px 5px black;
    }

    @media (max-width: 768px) {
        img{
            width: 400px;
            height: 500px;
            padding: 1rem;
            box-shadow: 5px 5px 5px black;
        }
    }
    @media (max-width: 480px) {
        img{
            width: 300px;
            height: 400px;
            padding: 1rem;
            box-shadow: 5px 5px 5px black;
            
        }
        
    }
}
.donuts-container{
    width: 600px;
    height: 400px;

} 
@media (max-width: 768px) {
.donuts-container {
    width: 500px;
    height: 300px;

}
}
@media (max-width: 480px) {
.donuts-container {
    width: 350px;
    height: 200px;

}
}